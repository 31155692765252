import React from 'react';
import { HeaderMeta } from '../../shared/ui/Helmet';
import { PAGE_QUERY } from '../../shared/queries/page';
import { DataQuery } from '../../shared/helpers/DataQuery';
import { PageExhibitionsAuctions } from './ui/PageExhibitionsAuctions';
import { PageVisitGarden } from './ui/PageVisitGarden';
import { PageVisitGardenForm } from './ui/PageVisitGardenForm';
import { PageExploreGarden } from './ui/PageExploreGarden';
import { PageSculpture } from './ui/PageSculpture';
import { PageGrouping } from './ui/PageGrouping';
import { PageContact } from './ui/PageContact';
import { Page } from './ui/Page';
import { PageCatalogue } from './ui/PageCatalogue';
import { PageUnsubscribe } from './ui/PageUnsubscribe';
import { PageSubscribe } from './ui/PageSubscribe';
import { Box } from 'rebass';
import { getHeroDetails } from '@dylanlewis/shared';
import { NoMatch } from '../../shared/ui/NoMatch';

export class PageContainer extends React.Component {
  render() {
    const {
      match: {
        params: { slug, parent },
      },
    } = this.props;
    const s = slug || 'dylan-lewis-studio';

    return (
      <Box>
        <DataQuery
          query={PAGE_QUERY}
          variables={{ slug: s }}
          render={data => {
            if (!data.viewPage) {
              return <NoMatch />;
            }

            const heroDetails = getHeroDetails(data.viewPage);

            return (
              <React.Fragment>
                {data.viewPage && data.viewPage.seo ? (
                  <HeaderMeta seo={data.viewPage.seo} />
                ) : null}

                {template(
                  data.viewPage.template,
                  this.props,
                  data,
                  parent,
                  heroDetails,
                )}
              </React.Fragment>
            );
          }}
        />
      </Box>
    );
  }
}

const template = (template, props, data, parent, heroDetails) => {
  switch (template) {
    case 'auctions_exhibitions':
      return (
        <PageExhibitionsAuctions
          page={data.viewPage}
          {...props}
          parent={parent}
        />
      );
    case 'visit':
      return (
        <PageVisitGarden
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
    case 'visit_form':
      return (
        <PageVisitGardenForm
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
    case 'explore':
      return (
        <PageExploreGarden
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
    case 'sculpture':
      return (
        <PageSculpture
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );

    case 'work':
      return (
        <PageSculpture
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );

    case 'sculpture-groupings':
      return (
        <PageGrouping
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );

    case 'contact':
      return (
        <PageContact
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
    case 'catalogue':
      return (
        <PageCatalogue
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
    case 'unsubscribe':
      return (
        <PageUnsubscribe
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
    case 'subscribe':
      return (
        <PageSubscribe
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );

    default:
      return (
        <Page
          page={data.viewPage}
          {...props}
          parent={parent}
          heroDetails={heroDetails}
        />
      );
  }
};
