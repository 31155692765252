import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  CardSmall,
  StyledLink,
  Container,
  Row,
  Column,
  Text,
} from '@dylanlewis/shared';

export const AuctionsExhibitionsList = props => {
  const { data, parent } = props;

  return (
    <Container pb={[2, 5]}>
      {data.map((i, index) => {
        return (
          <Row mb={[2, 4]} key={index}>
            <Column width={['0px', 1 / 12]} />

            <Column width={[1, 1 / 12]}>
              <Text fontFamily="sans" fontWeight="500">
                {i.date ? i.date.map(date => date.name) : ''}
              </Text>
            </Column>

            <Column width={[1, 2 / 12]}>
              <Text fontFamily="sans" textTransform="capitalize">
                {i.auction_exhibition_type.map(type => type.name)}
              </Text>
            </Column>

            <Column width={[1, 9 / 12]}>
              <StyledLink
                to={`/${parent}/${i.type}/${i.slug}`}
                title={data.title}
                as={NavLink}
              >
                <CardSmall
                  renderVisual={
                    i.featured_media && i.featured_media.media_details ? (
                      <img
                        alt={i.title}
                        width={
                          i.featured_media.media_details.sizes.thumbnail.width
                        }
                        height={
                          i.featured_media.media_details.sizes.thumbnail.height
                        }
                        src={
                          i.featured_media.media_details.sizes.thumbnail
                            .source_url
                        }
                      />
                    ) : null
                  }
                  description={i.acf.description}
                  title={i.title}
                />
              </StyledLink>
            </Column>
          </Row>
        );
      })}
    </Container>
  );
};
