import React from 'react';
import Helmet from 'react-helmet-async';

export const HeaderMeta = ({ seo }) => {
  return (
    <Helmet
      defaultTitle={
        seo.title !== '' ? seo.title : 'Dylan Lewis Studio & Sculpture Garden'
      }
      meta={[
        {
          name: 'description',
          content:
            seo.metadesc !== ''
              ? seo.metadesc
              : 'Dylan is widely recognised as one of the world’s foremost sculptors of the animal form, he initially focused on the big cats; in recent years, he has used the human figure to explore our relationship with our inner wilderness.',
        },
        {
          name: 'og:title',
          content:
            seo['opengraph-title'] !== ''
              ? seo['opengraph-title']
              : 'Dylan Lewis Studio & Sculpture Garden',
        },
        {
          name: 'og:description',
          content:
            seo['opengraph-description'] !== ''
              ? seo['opengraph-description']
              : 'Dylan is widely recognised as one of the world’s foremost sculptors of the animal form, he initially focused on the big cats; in recent years, he has used the human figure to explore our relationship with our inner wilderness.',
        },
        { name: 'og:url', content: '' },
        {
          name: 'og:site_name',
          content: seo.title
            ? seo.title
            : 'Dylan Lewis Studio & Sculpture Garden',
        },
        { name: 'og:type', content: `business` },
        { name: 'og:image', content: seo['opengraph-image'] || null },
        // { name: "fb:app_id", content: "1112913182429554" },
        { name: 'twitter:card', content: 'summary_large_image' },
        {
          name: 'twitter:title',
          content: seo['twitter-title'] !== '' ? seo['twitter-title'] : null,
        },
        {
          name: 'twitter:description',
          content:
            seo['twitter-description'] !== ''
              ? seo['twitter-description']
              : null,
        },
        {
          name: 'twitter:image:src',
          content: seo['twitter-image'] !== '' ? seo['twitter-image'] : null,
        },
      ]}
      htmlAttributes={{ lang: 'en' }}
    />
  );
};
