import {
  ButtonText,
  CardPoster,
  Column,
  Container,
  getButton,
  GridHomeGarden,
  GridHomeStudio,
  GridItems,
  HeroLanding,
  Poem,
  Row,
  SectionHeading,
  SectionPreview,
  StyledLink,
  Text,
} from '@dylanlewis/shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import { DataQuery } from '../../../shared/helpers/DataQuery';
import { ITEMS_QUERY } from '../../../shared/queries/item';
import { ModalContent } from '../../../shared/ui/ModalContent';

export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { height: null };
  }

  render() {
    const { page, heroDetails } = this.props;

    return (
      <React.Fragment>
        <HeroLanding
          template={page.template}
          visual={heroDetails.visual.props.src}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title}
          description={heroDetails.description}
          renderCta={
            heroDetails.buttons
              ? heroDetails.buttons.map((b, index) => (
                  <Box
                    width={['100%', 'auto']}
                    key={index}
                    mr={[5]}
                    mb={[2, 2, '0px']}
                  >
                    {getButton(b.button, true, NavLink)}
                  </Box>
                ))
              : null
          }
        >
          {heroDetails.content && (
            <Text size="body" html={heroDetails.content} />
          )}
        </HeroLanding>
        {page.template && page.acf.grid && (
          <Container className="container">
            <Row flexDirection="column">
              <Column width={[1, 5 / 12]} mx="auto">
                <SectionHeading
                  title={page.acf.grid.title && page.acf.grid.title}
                  subtitle={page.acf.grid.subtitle && page.acf.grid.subtitle}
                />
              </Column>
            </Row>

            {page.acf.grid.items &&
              (page.template && page.template === 'garden' ? (
                <GridHomeGarden
                  items={page.acf.grid.items}
                  parent={page.template}
                  linkAs={NavLink}
                />
              ) : (
                <GridHomeStudio
                  items={page.acf.grid.items}
                  parent={page.template}
                  linkAs={NavLink}
                />
              ))}
          </Container>
        )}

        {page.template && page.template === 'garden' && page.acf.essay_options && (
          <SectionPreview
            template={page.template}
            {...page.acf.essay_options}
            renderCta={
              page.acf.essay_options.buttons.length > 0 &&
              page.acf.essay_options.buttons.map((b, index) => {
                return getButton(
                  b.button,
                  false,
                  <ModalContent {...b.button} />,
                );
              })
            }
          />
        )}

        {/* Removed till later */}
        {/*
        {page.template && page.template === "studio" ? (
          <React.Fragment>
            <Space bottom size={spacing.space10}>
              <SectionHeading
                subtitle="Featured"
                title="Exhibitions &amp; Auctions"
              />
              <DataQuery
                query={AUCTIONS_AND_EXHIBITIONS_QUERY_FEATURED}
                variables={{
                  postType: "auctions_exhibitions",
                  per_page: 100,
                  order: "asc"
                }}
                render={data => {
                  return (
                    <div className="container">
                      <div className="row">
                        <GridItems
                          items={data.viewItems}
                          parent={page.template}
                      linkAs={NavLink}
                        />
                      </div>
                    </div>
                  );
                }}
              />
            </Space>
          </React.Fragment>
        ) : null} */}

        {page.template && page.template === 'garden' ? (
          <Poem {...page.acf.poem} />
        ) : null}

        {page.template && page.template === 'garden' ? (
          <Box mb={[5, 10]}>
            <SectionHeading
              title="Featured Events"
              subtitle="What’s happening"
            />
            <DataQuery
              query={ITEMS_QUERY}
              variables={{ postType: 'events', featured: true }}
              render={data => {
                return (
                  <React.Fragment>
                    <GridItems
                      items={data.viewItems}
                      parent={page.template}
                      linkAs={NavLink}
                    />
                    <Flex justifyContent="center">
                      <StyledLink to="/garden/events" as={NavLink}>
                        <ButtonText as="div">View All</ButtonText>
                      </StyledLink>
                    </Flex>
                  </React.Fragment>
                );
              }}
            />
          </Box>
        ) : null}

        {page.template && page.template === 'garden'
          ? page.acf.about && (
              <CardPoster
                visual={page.acf.about.visual.gallery[0].url}
                subtitle={page.acf.about.subtitle && page.acf.about.subtitle}
                title={page.acf.about.headline && page.acf.about.headline}
                renderCta={
                  page.acf.about.buttons && page.acf.about.buttons.length > 0
                    ? page.acf.about.buttons.map((b, index) => (
                        <Flex alignItems="center" key={index}>
                          <Box
                            width={['100%', 'auto']}
                            key={index}
                            mx={[2]}
                            mb={[2, 2, '0px']}
                          >
                            {getButton(b.button, false, NavLink)}
                          </Box>
                        </Flex>
                      ))
                    : null
                }
              />
            )
          : null}
      </React.Fragment>
    );
  }
}
