import React from 'react';
import { Banner } from '@dylanlewis/shared';
import LinkGroup from '../components/LinkGroup';
import { Box } from 'rebass';

const BannerWrapper = ({ hasVisual, content, ...rest }) => {
  return (
    <Banner
      {...rest}
      image={hasVisual && content.imgUrl ? content.imgUrl : null}
      title={content.title && content.title}
      description={content.description && content.description}
      renderCta={
        content.link && (
          <Box mt={2}>
            <LinkGroup links={[content.link]} />
          </Box>
        )
      }
    />
  );
};

export default BannerWrapper;
