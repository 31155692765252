import {
  ButtonText,
  GridItems,
  SectionHeading,
  Space,
  spacing,
} from '@dylanlewis/shared';
import React from 'react';
import { Link } from 'react-router-dom';
import { DataQuery } from '../../../shared/helpers/DataQuery';
import {
  AUCTIONS_AND_EXHIBITIONS_QUERY,
  ITEMS_QUERY,
} from '../../../shared/queries/item';
import { AuctionsExhibitionsList } from '../../Page/ui/AuctionsExhibitionsList';
import { GardenMap } from '../../Page/ui/GardenMap';

export const RelatedItems = props => {
  const { page, postType, parent } = props;

  if (postType === 'auctions_exhibitions') {
    return null;
    return (
      <DataQuery
        query={AUCTIONS_AND_EXHIBITIONS_QUERY}
        variables={{
          postType: page.type,
          category:
            page.auction_exhibition_type && page.auction_exhibition_type.length
              ? Number(page.auction_exhibition_type[0].id)
              : null,
          per_page: 100,
        }}
        render={data => {
          let filteredData = data.viewItems.filter(
            value => value.slug !== page.slug,
          );

          if (filteredData.length) {
            return (
              <React.Fragment>
                <SectionHeading subtitle={'Related'} />
                <div className="container">
                  <div className="row align-items-end">
                    <AuctionsExhibitionsList
                      data={filteredData}
                      parent={parent}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <Space bottom size={spacing.space10}>
                      <Link to="/studio/auctions-exhibitions">
                        <ButtonText>View All</ButtonText>
                      </Link>
                    </Space>
                  </div>
                </div>
              </React.Fragment>
            );
          }

          return (
            <div className="d-flex justify-content-center">
              <Space bottom size={spacing.space10}>
                <Link to="/studio/auctions-exhibitions">
                  <ButtonText>View All</ButtonText>
                </Link>
              </Space>
            </div>
          );
        }}
      />
    );
  }

  if (postType === 'sculpture_groupings') {
    //sculpture_groupings changed to "Explore the Garden"
    return <GardenMap group={props.page.slug} />;
  }

  if (postType === 'sculpture') {
    return (
      <DataQuery
        query={AUCTIONS_AND_EXHIBITIONS_QUERY}
        variables={{
          postType: page.type,
          category:
            page.sculpture_type && page.sculpture_type.length
              ? Number(page.sculpture_type[0].id)
              : null,
          per_page: 100,
        }}
        render={data => {
          let filteredData = data.viewItems
            .filter(value => value.slug !== page.slug)
            .filter(value => value.template !== 'sculpture_parent');

          if (filteredData.length) {
            return (
              <React.Fragment>
                {page.content && (
                  <SectionHeading
                    subtitle={
                      page.template === 'sculpture_parent'
                        ? `${page.title} Sculptures`
                        : 'Related Sculptures'
                    }
                  />
                )}

                <div className="container">
                  <div className="row align-items-end justify-content-center">
                    <GridItems
                      items={filteredData}
                      parent={parent}
                      linkAs={Link}
                    />
                  </div>
                </div>
              </React.Fragment>
            );
          } else {
            return null;
          }
        }}
      />
    );
  }

  return (
    <DataQuery
      query={ITEMS_QUERY}
      variables={{ postType: page.type }}
      render={data => {
        let filteredData = data.viewItems.filter(
          value => value.slug !== page.slug,
        );

        return (
          <React.Fragment>
            <SectionHeading subtitle={'Related'} />
            <div className="container">
              <div className="row align-items-end justify-content-center">
                <GridItems
                  items={filteredData}
                  parent={parent}
                  linkAs={Link}
                  linkType="a"
                />
              </div>
            </div>
          </React.Fragment>
        );
      }}
    />
  );
};
