import * as React from 'react';
import styled from 'styled-components';
import { Text, fonts, colors } from '@dylanlewis/shared';

const StyledInput = styled.input`
  border-radius: 0px;
  background: ${colors.bgLight};
  border-color: ${props => (props.error ? 'red' : colors.bgLight)};
  padding: 8px;
  font-family: ${fonts.sans};
  &:active,
  &:focus {
    background: ${colors.bgLight};
    border-color: ${colors.borderDark};
    outline: none;
    box-shadow: none;
  }
`;

export const TextField = props => {
  return (
    <div className={`${props.col ? props.col : 'col-3'}`}>
      <div className="form-group">
        <Text mb={[0]} fontSize={'body'} fontWeight="500" fontFamily={'sans'}>
          {props.label}
        </Text>

        <StyledInput
          className="form-control"
          defaultValue={props.value}
          name={props.name}
          disabled={props.disabled}
          type={props.type}
          placeholder={props.placeholder}
          error={props.error}
        />
        {props.message && (
          <Text size="error">
            <i>{props.message}</i>
          </Text>
        )}
        {props.error && (
          <Text color="red" size="error">
            <i>{props.error}</i>
          </Text>
        )}
      </div>
    </div>
  );
};
