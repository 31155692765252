import React from 'react';
import {
  Hero,
  Collapse,
  CollapseWrapper,
  Text,
  Heading,
  Container,
  Row,
  Column,
  getButton,
  Button
} from '@dylanlewis/shared';
import { Box, Flex } from 'rebass';
import { FormContainer } from '../../Form/FormContainer';
import { visitTheGardenForm } from '../../../common/forms';
import serialize from 'form-serialize';
import { MapWithAMarker } from '../ui/GoogleMap';
import { BOOKING } from '../mutations/mutations';
import { Mutation } from 'react-apollo';
import {
  convertDateToString,
  convertTimeToString,
} from '../../../common/utils';

export class PageVisitGarden extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null,
      time: null,
      emailHasBeenSent: false,
      emailError: false,
      errors: {},
    };
  }

  validateForm(obj) {
    let errors = {};

    let hasErrors = false;

    visitTheGardenForm().forEach(field => {
      const { name, required } = field;

      if (required && !obj[name]) {
        errors[name] = 'Required';
      } else if (obj.email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmail = re.test(String(obj.email).toLowerCase());
        if (!isEmail) {
          errors.email = 'Invalid email address';
        }
      } else if (obj.time) {
        const d = new Date(obj.time);
        const hour = d.getHours();
        const min = d.getMinutes();

        if (hour === 15 && min > 30) {
          errors.time = 'Please select a time between 9:00 and 15:30';
        }

        if (hour < 9 || hour > 15) {
          errors.time = 'Please select a time between 9:00 and 15:30';
        }
      }
    });

    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      hasErrors = true;
    }

    return hasErrors;
  }

  async onSubmit(e, send) {
    e.preventDefault();

    let obj = serialize(e.target, { hash: true });

    obj.date = this.state.date;
    obj.time = this.state.time;

    const hasErrors = this.validateForm(obj);

    if (hasErrors) return null;

    const {
      firstName,
      lastName,
      email,
      phone,
      comments,
      number,
      privateTour,
      agree,
      mailingList,
      date,
      time,
      country,
      visitedBefore,
    } = obj;

    await send({
      variables: {
        fields: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          message: comments,
          numberOfGuests: Number(number),
          privateTour: privateTour,
          termsAndConditionsAgreed: agree ? 'Agreed' : 'Not agreed',
          mailingList: mailingList ? 'Yes' : 'No',
          date: convertDateToString(date),
          time: convertTimeToString(time),
          country,
          visitedBefore,
        },
        enquiry: 'VISIT',
        form: 'BOOKING',
        confirmationEmail: true,
      },
    });

    if (e && e.target) e.target.reset();

    return null;
  }

  render() {
    const { page, heroDetails } = this.props;

    const handleDateChange = date => {
      this.setState({
        date: date,
      });
    };

    const handleTimeChange = time => {
      this.setState({
        time: time,
      });
    };

    const tours = page.acf.prepare_for_your_visit_options.information_options
    const collapse = page.acf.prepare_for_your_visit_options.information_options.filter((x, idx) => idx > 1)
    
    return (
      <Container>
        <Hero
          noCrop={heroDetails.noCrop}
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title}
        >
          {heroDetails.content && (
            <Text
              textAlign="center"
              size="body"
              as="span"
              html={heroDetails.content}
            />
          )}
        </Hero>

        <Box pb={[7, 15]}>
        {page.content && (
            <Text
              size="body"
              mb={[1, 3]}
              textAlign="center"
              as="span"
              html={page.content}
            />
          )}
        <Row justifyContent="space-between">
       
  <Column width={[1, 5 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
    <Text size="body" weight="medium" as="span" html={tours[0].title} mb={[2, 3]}/>
    <Box mb={[3]}>
      <Text
        size="body"
        as="span"
        html={tours[0].content}
      />
      <Button 
        mr={[1]}
        as="a"
        inverse
        variant={"primary"} 
        target={"_blank"}
        href={"https://account.dineplan.com/widgetframe/y2PYcR9M"}
      >
        Book a Self-Guided Visit
      </Button>
    </Box>
  </Column>
  <Column width={[1, 5 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
    <Text size="body" as="span" html={tours[1].title} mb={[2, 3]}/>
    <Box mb={[3]}>
      <Text
        size="body"
        as="span"
        html={tours[1].content}
      />
      <Button 
        as="a"
        mr={[1]}
        inverse
        variant={"primary"} 
        target={"blank"}
        href={"/garden/visit-the-garden-form/"}
        >Request a Private-Guided Tour</Button>
    </Box>
  </Column>
</Row>

          <Row justifyContent="space-between" mb={[2, 4]}>
            <Column width={[1, 9 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
             
              <CollapseWrapper
                renderCollapse={({ handleCollapse, openSections }) =>
                collapse.map(
                    (item, index) => (
                      <Collapse
                        key={`collapse-${index}`}
                        id={index}
                        isopen={!!openSections[index]}
                        handleCollapse={() => handleCollapse(index)}
                        openSections={openSections}
                        renderHeader={(isopen, onToggle) => (
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            onClick={() => onToggle()}
                            style={{ cursor: 'pointer' }}
                          >
                            <Text size="body" as="span" html={item.title} />
                            <Flex alignItems="flex-end" p={[3]}>
                              <i
                                style={{ color: '#8F8E8D', fontSize: '10px' }}
                                className={`${
                                  isopen
                                    ? 'icon icon-angle-up'
                                    : 'icon icon-angle-down'
                                }`}
                              />
                            </Flex>
                          </Flex>
                        )}
                        renderBody={
                          <Box mb={[3]}>
                            <Text
                              size="body"
                              as="span"
                              html={item.content}
                            />
                          </Box>
                        }
                      />
                    ),
                  )
                }
              />
            </Column>
          </Row>

<Row flexDirection="column">
            <Column width={[1]} mx="auto">
              {page.acf.map && (
                <Box mt={[5, 10]}>
                  <MapWithAMarker
                    containerElement={<div style={{ height: `460px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    coords={page.acf.map}
                  />
                </Box>
              )}
            </Column>
          </Row>
        </Box>
      </Container>
    );
  }
}
