import {
  bannerContent,
  CardPoster,
  Column,
  Container,
  getButton,
  ButtonText,
  GridItems,
  Hero,
  Row,
  Text,
  Timeline,
  CollapseWrapper,
  Collapse,
  VideoContainer,
} from '@dylanlewis/shared';
import InViewMonitor from 'react-inview-monitor';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Flex } from 'rebass';
import BannerWrapper from '../../../components/BannerWrapper';
import { DataQuery } from '../../../shared/helpers/DataQuery';
import { ITEMS_QUERY } from '../../../shared/queries/item';
import { Layout } from '../../../shared/ui/Layout';
import { ModalContent } from '../../../shared/ui/ModalContent';
import { Subscribe } from '../../Form/Newsletter/Subscribe';
import { GardenMap } from './GardenMap';

export const Page = props => {
  const { page, heroDetails } = props;
  return (
    <React.Fragment>
      <Container>
        <Hero
          noCrop={true}
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title ? heroDetails.title : ''}
          renderCta={
            heroDetails.buttons
              ? heroDetails.buttons.map((b, index) => {
                  return (
                    <Box key={index} mx={[1, 2]} mb={[2, 0]}>
                      {getButton(
                        b.button,
                        false,
                        NavLink,
                        b.button.type === 'modal' ? (
                          <ModalContent
                            {...b.button}
                            slug={b.button.page.post_name}
                            postType={b.button.page.post_type}
                          />
                        ) : null,
                      )}
                    </Box>
                  );
                })
              : null
          }
        >
          {heroDetails.content && (
            <Text
              size="body"
              textAlign="center"
              as="span"
              html={heroDetails.content}
            />
          )}

          {page.slug === 'events' && (
            <Box
              mt={[2, 4]}
              mx={'auto'}
              width={('100%', '50%')}
              className="text-center"
            >
              <Subscribe title={null} width="100%" />
            </Box>
          )}
        </Hero>

        {page.content && (
          <Row flexDirection="column">
            <Column width={[1, 8 / 12]} mx="auto">
              <Text size="body" as="span" html={page.content} />
            </Column>
          </Row>
        )}
      </Container>

      {/* Layout Blocks should include Container width in props - this allows fullscreen layouts */}
      {page.acf.content_blocks2 && <Layout blocks={page.acf} />}

      {page.acf.button_options &&
        page.acf.button_options.buttons &&
        page.acf.button_options.buttons.map((b, index) => {
          return (
            <Row
              key={index}
              mb={[3, 8]}
              justifyContent="center"
              alignItems="center"
              flexDirection={['column', 'row']}
            >
              {getButton(b.button, false, NavLink)}
            </Row>
          );
        })}

      <Container>
        {/* Timeline is on About Dylan Lewis Page */}
        {page.acf.timeline && page.acf.timeline.length > 0 ? (
          <CollapseWrapper
            renderCollapse={({ handleCollapse, openSections }) => (
              <Collapse
                handleCollapse={() => handleCollapse(index)}
                openSections={openSections}
                renderHeader={(isopen, onToggle) => (
                  <Flex
                    mb={[5, 10]}
                    mt={[2, 4]}
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => onToggle()}
                    style={{ cursor: 'pointer' }}
                  >
                    <ButtonText display="primary" textAlign="center">
                      {isopen ? 'Close Biography' : 'Show Biography'}
                    </ButtonText>
                  </Flex>
                )}
                renderBody={
                  <Timeline headline={null} moments={page.acf.timeline} />
                }
              />
            )}
          />
        ) : null}
      </Container>

      {page.acf.related_items && page.acf.related_items !== 'none' ? (
        <DataQuery
          query={ITEMS_QUERY}
          variables={{ postType: page.acf.related_items, per_page: 100 }}
          render={data => {
            return (
              <GridItems items={data.viewItems} linkAs={NavLink} {...props} />
            );
          }}
        />
      ) : null}

      {page.slug === 'explore' && <GardenMap />}

      {page.slug === 'events' ? (
        <BannerWrapper hasVisual={true} content={bannerContent.event} />
      ) : null}

      {/* To display About Dylan on the Garden "About" page */}
      {page.acf.about && page.acf.about.headline ? (
        <>
          <CardPoster
            visual={page.acf.about.visual.gallery[0].url}
            subtitle={page.acf.about.subtitle && page.acf.about.subtitle}
            title={page.acf.about.headline && page.acf.about.headline}
            renderCta={
              page.acf.about.buttons && page.acf.about.buttons.length > 0
                ? page.acf.about.buttons.map((b, index) => (
                    <Flex alignItems="center" key={index}>
                      <Box width={['100%', 'auto']} mx={[2]} mb={[2, 2, '0px']}>
                        {getButton(b.button, false, NavLink)}
                      </Box>
                    </Flex>
                  ))
                : null
            }
          />
          {page.acf.about.video_visual && (
            <InViewMonitor childPropsInView={{ inView: true }}>
              <Container py={[5, 8]} width={['100%', '100%', '50%']}>
                <VideoContainer
                  {...page.acf.about.video_visual}
                  videoType={page.acf.about.video_visual.video_type}
                  videoID={page.acf.about.video_visual.video_id}
                  videoCaption={page.acf.about.video_visual.video_caption}
                />
              </Container>
            </InViewMonitor>
          )}
        </>
      ) : null}
    </React.Fragment>
  );
};
