import React from 'react';
import {
  Text,
  Hero,
  Space,
  spacing,
  Container,
  Row,
  Column,
} from '@dylanlewis/shared';

import { FormContainer } from '../../Form/FormContainer';
import { subscribe } from '../../../common/forms';
import serialize from 'form-serialize';
import { Mutation } from 'react-apollo';
import { SUBSCRIBE_NEWSLETTER } from '../mutations/mutations';

export class PageSubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      emailHasBeenSent: false,
    };
  }

  validateForm(obj) {
    let errors = {};

    subscribe().forEach(field => {
      const { name, required } = field;

      if (required && !obj[name]) {
        errors[name] = 'Required';
      }

      if (obj.email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmail = re.test(String(obj.email).toLowerCase());
        if (!isEmail) {
          errors.email = 'Invalid email address';
        }
      }
    });

    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      return true;
    } else {
      this.setState({ errors: {} });
      return false;
    }
  }

  render() {
    const { page, heroDetails } = this.props;

    const onSubmit = async (e, send) => {
      e.preventDefault();

      var obj = serialize(e.target, { hash: true });

      const hasErrors = this.validateForm(obj);

      if (hasErrors) return null;

      await send({
        variables: {
          input: {
            ...obj,
          },
        },
      });

      if (e && e.target) e.target.reset();

      return null;
    };

    return (
      <Container>
        <Hero
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title}
        >
          {heroDetails.content && (
            <Text size="body" as="span" html={heroDetails.content} />
          )}
        </Hero>
        <Space bottom size={spacing.space10}>
          <Space padding bottom size={spacing.space5}>
            <Row justifyContent="center">
              <Column width={[1, 8 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
                {!this.state.emailHasBeenSent ? (
                  <Mutation
                    mutation={SUBSCRIBE_NEWSLETTER}
                    onCompleted={response => {
                      if (response.subscribeToNewsletter) {
                        this.setState({ emailHasBeenSent: true });
                      }
                    }}
                    onError={() => this.setState({ emailError: true })}
                  >
                    {(send, { loading, error }) => {
                      return (
                        <FormContainer
                          form={subscribe(this.state.errors)}
                          isLoading={loading}
                          onSubmit={e => onSubmit(e, send)}
                          buttonText="Subscribe"
                        />
                      );
                    }}
                  </Mutation>
                ) : (
                  <Text>Thank you for subscribing.</Text>
                )}
              </Column>
            </Row>
          </Space>
        </Space>
      </Container>
    );
  }
}
