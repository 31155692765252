import React from 'react';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Loading } from '@dylanlewis/shared';

const dataQuery = props => {
  return (
    <Query query={props.query} variables={{ ...props.variables }}>
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error) {
          for (const err of error.graphQLErrors) {
            const { extensions } = err;
            if (extensions) {
              const { exception } = extensions;
              if (exception) {
                const { statusCode } = exception;
                if (statusCode === 404) {
                  props.history.push('/404');
                }
              }
            }
          }
        }

        if (!data) {
          props.history.push('/search');
        }

        return props.render(data);
      }}
    </Query>
  );
};

export const DataQuery = withRouter(dataQuery);
