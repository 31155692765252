import moment from 'moment';

export function findLayout(arr, acf_fc_layout) {
  if (!arr || typeof arr !== 'object') return;
  if (typeof acf_fc_layout === 'undefined' || acf_fc_layout === null) return;

  function customFind(layout) {
    if (typeof acf_fc_layout !== 'undefined' || acf_fc_layout !== null) {
      return layout.acf_fc_layout === acf_fc_layout;
    } else return;
  }
  return arr.find(customFind);
}

export const convertDateToString = date => {
  const d = moment(date).format('YYYY/MM/DD');

  const dateString = String(d);

  return dateString;
};

export const convertTimeToString = time => {
  const t = moment(time).format('h:mm a');

  const timeString = String(t);

  return timeString;
};
