import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import './date.css';
import { Text } from '@dylanlewis/shared';
import moment from 'moment';

export const TimePicker = props => {
  // Initialize local state with 09:30 in 24-hour format
  const [time, setTime] = useState(moment('09:30', 'HH:mm'));

  // Effect to update the time when props.dateTime changes
  useEffect(() => {
    if (props.dateTime) {
      setTime(moment(props.dateTime));
    }
  }, [props.dateTime]);

  const handleChange = (thing) => {
    setTime(thing);
    props.handleTimeChange(thing._d);
  };

  return (
    <div className={`${props.col ? props.col : 'col-12'}`}>
      <div className="form-group">
        <Text mb={[0]} fontSize={'body'} fontWeight="500" fontFamily={'sans'}>
          {props.label}
        </Text>
        <Datetime
          dateFormat={false}
          timeFormat="HH:mm"  // Set time format to 24-hour
          closeOnSelect={true}
          value={time}  // Use local state for value
          onChange={handleChange}
          timeConstraints={{ 
            minutes: { min: 0, max: 59, step: 30 }, 
            hours: { min: 8, max: 15, step: 1 }
          }}
        />
        {props.error && (
          <Text color="red" size="error">
            <i>{props.error}</i>
          </Text>
        )}
      </div>
    </div>
  );
};
