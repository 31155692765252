import React from 'react';
import { HeaderMeta } from '../../shared/ui/Helmet';
import { HomePage } from './ui/HomePage';
import { getHeroDetails } from '@dylanlewis/shared';

export class HomeContainer extends React.Component {
  render() {
    const { pages, seo } = this.props;

    return (
      <React.Fragment>
        {seo && <HeaderMeta seo={seo} />}
        <HomePage page={pages} heroDetails={getHeroDetails(pages)} />
      </React.Fragment>
    );
  }
}
