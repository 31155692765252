import gql from 'graphql-tag';

export const CONTACT = gql`
  mutation submitForm(
    $fields: JSON
    $enquiry: Enquiry
    $form: Form!
    $confirmationEmail: Boolean
  ) {
    submitForm(
      input: {
        form: $form
        enquiry: $enquiry
        fields: $fields
        confirmationEmail: $confirmationEmail
      }
    )
  }
`;

export const BOOKING = gql`
  mutation submitForm(
    $fields: JSON
    $form: Form!
    $confirmationEmail: Boolean
  ) {
    submitForm(
      input: {
        form: $form
        enquiry: VISIT
        fields: $fields
        confirmationEmail: $confirmationEmail
      }
    )
  }
`;

export const CATALOGUE = gql`
  mutation submitForm($fields: JSON, $form: Form!) {
    submitForm(input: { form: $form, fields: $fields })
  }
`;

export const UNSUBSCRIBE = gql`
  mutation unsubscribe($input: UnsubscribeInput) {
    unsubscribe(input: $input)
  }
`;

export const SUBSCRIBE_NEWSLETTER = gql`
  mutation subscribe($input: SubscribeInput) {
    subscribeToNewsletter(input: $input)
  }
`;
