import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ButtonText,
  fonts,
  fontWeight,
  tracking,
  colors,
} from '@dylanlewis/shared';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

const StyledTitle = styled.h6`
  font-family: ${fonts.sans};
  font-size: 19px;
  font-weight: ${fontWeight.medium};
  letter-spacing: ${tracking.small};
  color: ${props => props.theme.colors.text};
`;

const StyledInput = styled.input`
  border-radius: 0px;
  background: ${colors.bgLight};
  border-color: ${props => (props.error ? 'red' : colors.bgLight)};
  padding: 8px;
  font-family: ${fonts.sans};
  &:active,
  &:focus {
    background: ${colors.bgLight};
    border-color: ${colors.borderDark};
    outline: none;
    box-shadow: none;
  }
  width: 100%;
`;

const StyledButtonText = styled(ButtonText)`
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 9;
  cursor: pointer;
  border: none;
  background: transparent;
`;

const StyledWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.width};
`;

export const Subscribe = props => {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(null);
  const { title, width } = props;
  let input;
  return (
    <React.Fragment>
      <Mutation mutation={NEWSLETER_MUTATION}>
        {(subscribe, { data }) => (
          <form
            onSubmit={e => {
              e.preventDefault();
              setLoading(true);
              subscribe({ variables: { email: input.value } });
              input.value = '';
              setLoading(false);
              setSent('Thank you for subscribing');
            }}
          >
            {title && (
              <label htmlFor="fieldEmail">
                <StyledTitle>{title}</StyledTitle>
              </label>
            )}
            {loading ? <StyledTitle>Submitting Form</StyledTitle> : null}
            {sent ? <StyledTitle>{sent}</StyledTitle> : null}
            <div className="form-group">
              <StyledWrapper className="StyledWrapper" width={width}>
                <StyledInput
                  id="fieldEmail"
                  name="email"
                  type="email"
                  className="js-cm-email-input form-control"
                  required
                  ref={node => {
                    input = node;
                  }}
                  placeholder="Email"
                />

                <StyledButtonText
                  as="button"
                  type="submit"
                  role="button"
                  aria-label="Subscribe to Newsletter"
                  disabled={loading}
                >
                  <i className="icon icon-arrow-right" />
                </StyledButtonText>
              </StyledWrapper>
            </div>
          </form>
        )}
      </Mutation>
    </React.Fragment>
  );
};

Subscribe.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
};

Subscribe.defaultProps = {
  title: 'Subscribe',
  width: '90%',
};

const NEWSLETER_MUTATION = gql`
  mutation subscribe($email: String!, $url: String) {
    subscribe(email: $email, url: $url)
  }
`;
