import React from 'react';
import { PAGE_QUERY } from '../queries/page';
import { ITEM_QUERY } from '../queries/item';
import { Page } from '../../modules/Page/ui/Page';
import { DataQuery } from '../helpers/DataQuery';
import { getHeroDetails } from '@dylanlewis/shared';
import { Item } from '../../modules/Item/ui/Item';

export const ModalContent = buttonData => {
  if (buttonData.page.post_type === 'page') {
    return (
      <DataQuery
        query={PAGE_QUERY}
        variables={{
          slug: buttonData.page.post_name,
        }}
        render={data => {
          const heroDetails = getHeroDetails(data.viewPage);

          return (
            <Page
              page={data.viewPage}
              props={data}
              isModal
              heroDetails={heroDetails}
            />
          );
        }}
      />
    );
  } else {
    return (
      <DataQuery
        query={ITEM_QUERY}
        variables={{
          slug: buttonData.slug,
          postType: buttonData.postType,
        }}
        render={data => {
          return <Item page={data.viewItem} props={data} isModal />;
        }}
      />
    );
  }
};
