import * as React from 'react';
import { Text, Space, spacing } from '@dylanlewis/shared';

export const CheckBoxField = props => {
  return (
    <div className="col-12">
      <div className="form-group">
        <div className="form-check ">
          <input
            type="checkbox"
            className="form-check-input"
            id={props.id}
            name={props.name}
            error={props.error}
          />
          <Space size={spacing.space1}>
            <label
              // className="form-check-label border border-danger"
              className={`form-check-label ${props.error &&
                'border border-danger'}`}
              htmlFor={props.id}
            >
              <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                <Text fontSize={'16px'} fontFamily={'sans'}>
                  {props.label}
                </Text>
              </div>
            </label>
          </Space>
          {props.error && (
            <Text color="red" size="error">
              <i>{props.error}</i>
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};
