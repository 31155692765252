import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import './date.css';
import { Text } from '@dylanlewis/shared';
import moment from 'moment';

export const DateSelector = (props) => {
  const isValidDate = (current) => {
    const selectedDay = moment(current).startOf('day');
    const today = moment().startOf('day');
    
    // Exclude Sundays (0) and Mondays (1)
    const dayOfWeek = selectedDay.day();
    
    // Ensure it's after today and not Sunday or Monday
    return selectedDay.isAfter(today) && dayOfWeek !== 0 && dayOfWeek !== 1;
};

  return (
    <div className={`${props.col ? props.col : 'col-12'}`}>
      <div className="form-group">
        <Text mb={[0]} fontSize={'body'} fontWeight="500" fontFamily={'sans'}>
          {props.label}
        </Text>
        <Datetime
          name={props.name}
          onChange={thing => props.handleDateChange(thing._d)}
          value={props.dateTime}
          timeFormat={false}
          closeOnSelect={true}
          isValidDate={isValidDate}
        />
        {props.error && (
          <Text color="red" size="error">
            <i>{props.error}</i>
          </Text>
        )}
      </div>
    </div>
  );
};
