import { Container, GridExplore, Hero } from '@dylanlewis/shared';
import React from 'react';
import { NavLink } from 'react-router-dom';

export class PageExploreGarden extends React.Component {
  render() {
    const { page, heroDetails } = this.props;

    return (
      <Container>
        <Hero
          noCrop={heroDetails.noCrop}
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title}
        />

        {page.acf.grid.items && (
          <GridExplore
            items={page.acf.grid.items}
            parent={'garden'}
            linkAs={NavLink}
          />
        )}
      </Container>
    );
  }
}
