import {
  BuilderLayout,
  Collapse,
  CollapseWrapper,
  Column,
  Container,
  GalleryZoom,
  globalSpringProps,
  Heading,
  Row,
  Text,
  VideoContainer,
  Banner,
  getButton,
} from '@dylanlewis/shared';
import React from 'react';
import InViewMonitor from 'react-inview-monitor';
import { animated } from 'react-spring';
import { Box, Flex } from 'rebass';
import { Link } from 'react-router-dom';

const AnimatedTextBlock = props => {
  const { inView, htmlText } = props;
  const springProps = globalSpringProps(inView);

  return (
    <animated.div style={springProps}>
      <Container maxWidth={['1230px']}>
        <Row flexDirection="column" alignItems="center" mb={[2, 3]}>
          <Column width={[1, 8 / 12]} m="auto">
            <Text size="body" as="span" html={htmlText} />
          </Column>
        </Row>
      </Container>
    </animated.div>
  );
};

AnimatedTextBlock.defaultProps = {
  inView: false,
};

export class Layout extends React.Component {
  getLayoutComponent(block, index) {
    switch (block.acf_fc_layout) {
      case 'text':
        return <BlockText {...block} key={index} />;
      case 'gallery':
        return <BlockGallery {...block} key={index} />;
      case 'builder':
        return <BlockBuilder {...block} key={index} />;
      case 'accordion':
        return <BlockAccordion {...block} key={index} />;
      case 'video':
        return <BlockVideo {...block} key={index} />;
      case 'banner':
        return <BlockBanner {...block} key={index} />;
      default:
        return null;
    }
  }

  render() {
    const {
      blocks: { content_blocks2 = [] },
    } = this.props;

    if (content_blocks2.length) {
      return content_blocks2.map((block, index) =>
        this.getLayoutComponent(block, index),
      );
    }
    return null;
  }
}

function BlockText(props) {
  return (
    <InViewMonitor childPropsInView={{ inView: true }}>
      <AnimatedTextBlock htmlText={props.text} />
    </InViewMonitor>
  );
}

function BlockBanner(props) {
  return (
    <Banner
      hasBorderTop={false}
      hasBorderBottom={false}
      title={props.headline}
      description={props.content}
      image={props.image}
      renderCta={
        props.buttons &&
        props.buttons.length &&
        props.buttons.map((b, index) => (
          <Box width={['100%', 'auto']} key={index} mt={[2]}>
            {getButton(b.button, false, Link)}
          </Box>
        ))
      }
    />
  );
}

function BlockGallery(props) {
  let imagesArr = [];
  for (let i = 0; i < props.gallery.length; i++) {
    imagesArr.push(props.gallery[i].sizes.large);
  }
  return (
    <GalleryZoom images={props.gallery} imagesArr={imagesArr} {...props} />
  );
}

function BlockBuilder(props) {
  return (
    <BuilderLayout
      fullWidth={props.fullWidth}
      rowJustifyContent={props.rowJustifyContent}
      rowAlignItems={props.rowAlignItems}
      {...props}
    />
  );
}

function BlockVideo(props) {
  return (
    <InViewMonitor childPropsInView={{ inView: true }}>
      <Container>
        <VideoContainer
          {...props}
          videoType={props.video_type}
          videoID={props.video_id}
          videoCaption={props.video_caption}
        />
      </Container>
    </InViewMonitor>
  );
}

function BlockAccordion(props) {
  return (
    <InViewMonitor childPropsInView={{ inView: true }}>
      <AccordionLayout {...props} />
    </InViewMonitor>
  );
}

const AccordionLayout = props => {
  const { inView, prepare_for_your_visit_options } = props;
  const springProps = globalSpringProps(inView);

  return (
    <animated.div style={springProps}>
      <Container>
        <Row flexDirection="column">
          <Column width={[1, 8 / 12]} mx="auto">
            <Heading
              mb={[1, 3]}
              size="title"
              textAlign="center"
              html={prepare_for_your_visit_options.headline}
            />

            <Text
              size="body"
              mb={[1, 3]}
              html={prepare_for_your_visit_options.intro}
            />

            <CollapseWrapper
              renderCollapse={({ handleCollapse, openSections }) =>
                prepare_for_your_visit_options.information_options.map(
                  (item, index) => (
                    <Collapse
                      key={`collapse-${index}`}
                      id={index}
                      isopen={!!openSections[index]}
                      handleCollapse={() => handleCollapse(index)}
                      openSections={openSections}
                      renderHeader={(isopen, onToggle) => (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          onClick={() => onToggle()}
                          style={{ cursor: 'pointer' }}
                        >
                          <Text
                            size="body"
                            as="span"
                            html={item.title}
                            lineHeight="1.3"
                            py={[1, 3]}
                          />
                          <Flex alignItems="flex-end" p={[3]}>
                            <i
                              style={{ color: '#8F8E8D', fontSize: '10px' }}
                              className={`${
                                isopen
                                  ? 'icon icon-angle-up'
                                  : 'icon icon-angle-down'
                              }`}
                            />
                          </Flex>
                        </Flex>
                      )}
                      renderBody={
                        <Box mb={[3]}>
                          <Text
                            size="collapseBody"
                            as="span"
                            html={item.content}
                          />
                        </Box>
                      }
                    />
                  ),
                )
              }
            />
          </Column>
        </Row>
      </Container>
    </animated.div>
  );
};

AccordionLayout.defaultProps = {
  inView: false,
};
