import gql from 'graphql-tag';

export const ITEM_QUERY = gql`
  query viewItem($slug: String!, $postType: String) {
    viewItem(slug: $slug, postType: $postType) {
      title
      slug
      content
      acf
      seo
      type
      template
      auction_exhibition_type {
        name
        id
      }
      sculpture_type {
        id
        name
      }
      date {
        name
      }
    }
  }
`;

export const ITEMS_QUERY = gql`
  query viewItems($postType: String, $per_page: Int, $featured: Boolean) {
    viewItems(postType: $postType, per_page: $per_page, featured: $featured) {
      title
      content
      slug
      acf
      seo
      slug
      type
      excerpt
      featured_media {
        link
        source_url
      }
      visualItem
      auction_exhibition_type {
        name
      }
      sculpture_type {
        name
      }
      date {
        name
      }
    }
  }
`;

export const AUCTIONS_AND_EXHIBITIONS_QUERY = gql`
  query viewItems($postType: String, $per_page: Int, $category: Int) {
    viewItems(
      postType: $postType
      per_page: $per_page
      category: $category
      order: "asc"
    ) {
      title
      content
      acf
      seo
      slug
      type
      excerpt
      template
      featured_media {
        link
        source_url
        media_details {
          sizes
        }
      }
      date {
        name
      }
      auction_exhibition_type {
        id
        name
        slug
        taxonomy
      }
      sculpture_type {
        id
        name
        slug
        taxonomy
      }
    }
  }
`;

export const AUCTIONS_AND_EXHIBITIONS_QUERY_FEATURED = gql`
  query viewItems($postType: String, $per_page: Int, $order: String) {
    viewItems(
      postType: $postType
      per_page: $per_page
      featured: true
      order: $order
    ) {
      title
      content
      acf
      seo
      slug
      type
      excerpt
      visualItem
      featured_media {
        title {
          rendered
        }
        link
        source_url
        media_details {
          sizes
        }
      }
      date {
        name
      }
      auction_exhibition_type {
        id
        name
        slug
        taxonomy
      }
    }
  }
`;
