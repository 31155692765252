import {
  Column,
  Footer,
  Header,
  Menu,
  menus,
  NavSwitch,
  NavSwitchWrapper,
  Row,
  theme,
} from '@dylanlewis/shared';
import React, { useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { HomeContainer } from '../../modules/Home/HomeContainer';
const HomeContainerComponent = ({ site, ...rest }) => (
  <React.Fragment>
    <HomeContainer site={site} {...rest} />
    <FooterComponent site={site} />
  </React.Fragment>
);

let NavSwitchContainer = props => {
  const { site, data, openComponent } = props;
  const { activeParent } = site;

  const [animate, setAnimate] = useState(false);

  return (
    <NavSwitchWrapper
      defaultState={{
        studio: activeParent === 'studio',
        garden: activeParent === 'garden',
      }}
      renderCollapse={({ handleCollapse }) => {
        const gardenOpen = activeParent === 'garden';
        const studioOpen = activeParent === 'studio';

        return (
          <React.Fragment>
            <NavSwitch
              id={'garden'}
              isopen={activeParent === 'garden'}
              handleCollapse={() => handleCollapse('studio', 'garden')}
              slug={'garden'}
              renderHeader={({ hover }) => (
                <div
                  onClick={() => {
                    if (!gardenOpen) {
                      setAnimate(true);
                      props.history.push('/garden');
                    }
                  }}
                >
                  <Header
                    isopen={gardenOpen}
                    enableSticky={gardenOpen}
                    primaryMenuItems={gardenOpen ? menus['garden'].header : []}
                    template={'garden'}
                    theme={theme['garden']}
                    hover={hover ? hover : null}
                    setAnimate={setAnimate}
                    linkAs={NavLink}
                  />
                </div>
              )}
              theme={theme['garden']}
              renderBody={
                gardenOpen ? (
                  openComponent
                ) : (
                  <HomeContainerComponent
                    pages={data.gardenPage}
                    site={site}
                    seo={
                      activeParent === 'studio'
                        ? data.studioPage.seo
                        : data.gardenPage.seo
                    }
                  />
                )
              }
              animate={animate}
            />
            <NavSwitch
              id={'studio'}
              isopen={activeParent === 'studio'}
              handleCollapse={() => handleCollapse('garden', 'studio')}
              slug={'studio'}
              renderHeader={({ hover }) => (
                <div
                  onClick={() => {
                    if (!studioOpen) {
                      setAnimate(true);
                      props.history.push('/studio');
                    }
                  }}
                >
                  <Header
                    isopen={studioOpen}
                    enableSticky={studioOpen}
                    primaryMenuItems={studioOpen ? menus['studio'].header : []}
                    template={'studio'}
                    theme={theme['studio']}
                    hover={hover ? hover : null}
                    setAnimate={setAnimate}
                    linkAs={NavLink}
                  />
                </div>
              )}
              theme={theme['studio']}
              renderBody={
                studioOpen ? (
                  openComponent
                ) : (
                  <HomeContainerComponent
                    pages={data.studioPage}
                    site={site}
                    seo={
                      activeParent === 'studio'
                        ? data.studioPage.seo
                        : data.gardenPage.seo
                    }
                  />
                )
              }
              animate={animate}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

const FooterComponent = ({ site }) => (
  <Footer template={site.activeParent}>
    <Row flexDirection="row" flexWrap="wrap">
      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={menus.garden.footer.title}
          items={menus.garden.footer.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>
      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={menus.studio.footer.title}
          items={menus.studio.footer.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>
      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={menus.contact.title}
          items={menus.contact.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
        <Menu
          menuTitle={menus.address.title}
          items={menus.address.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>

      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={menus.social.title}
          items={menus.social.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>
    </Row>
  </Footer>
);

NavSwitchContainer = withRouter(React.memo(NavSwitchContainer));

export { NavSwitchContainer };
