import {
  bannerContent,
  Column,
  Container,
  getButton,
  GridGrouping,
  Hero,
  Row,
  Text,
} from '@dylanlewis/shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box } from 'rebass';
import BannerWrapper from '../../../components/BannerWrapper';
import { DataQuery } from '../../../shared/helpers/DataQuery';
import { ITEMS_QUERY } from '../../../shared/queries/item';
import { Layout } from '../../../shared/ui/Layout';

export const PageGrouping = props => {
  const { page, heroDetails } = props;

  return (
    <React.Fragment>
      <Container>
        <Hero
          noCrop={heroDetails.noCrop}
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title ? heroDetails.title : page.title}
          renderCta={
            heroDetails.buttons
              ? heroDetails.buttons.map((b, index) => {
                  return (
                    <Box key={index} mx={[1, 2]}>
                      {getButton(b.button, false, NavLink)}
                    </Box>
                  );
                })
              : null
          }
        >
          {heroDetails.content && (
            <Text size="body" textAlign="center" as="span">
              {heroDetails.content}
            </Text>
          )}
        </Hero>
        {page.content && (
          <Row flexDirection="column">
            <Column width={[1, 8 / 12]} mx="auto">
              <Text size="body" as="span" html={page.content} />
            </Column>
          </Row>
        )}
      </Container>

      {/* Layout Blocks should include Container width in props - this allows fullscreen layouts */}
      {page.acf.content_blocks2 && <Layout blocks={page.acf} />}

      {page.acf.button_options &&
        page.acf.button_options.buttons &&
        page.acf.button_options.buttons.map((b, index) => {
          return (
            <Row key={index} mb={[3, 8]} justifyContent="center">
              {getButton(b.button, false, NavLink)}
            </Row>
          );
        })}

      {page.acf.related_items && page.acf.related_items !== 'none' ? (
        <DataQuery
          query={ITEMS_QUERY}
          variables={{ postType: page.acf.related_items, per_page: 100 }}
          render={data => {
            return (
              <Container>
                <GridGrouping
                  items={data.viewItems}
                  linkAs={NavLink}
                  {...props}
                />
              </Container>
            );
          }}
        />
      ) : null}

      <BannerWrapper hasVisual={true} content={bannerContent.visit} />
    </React.Fragment>
  );
};
