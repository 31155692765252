import React from 'react';
import { Button, ButtonText } from '@dylanlewis/shared';
import { NavLink } from 'react-router-dom';

export const setupPage = link => {
  return {
    label: link.label,
    as: NavLink,
    to: link.to,
  };
};

export const setupLink = link => {
  if (link.isExternal) {
    return {
      label: link.label || link.name,
      title: link.label || link.name,
      href: link.to,
      target: '_blank',
      as: 'a',
    };
  }

  return {
    label: link.label || link.name,
    link: link.to,
    to: link.to,
    as: link.to ? NavLink : 'span',
    ...options,
  };
};

export const BUTTON_TYPES = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  DOWNLOAD: 'Download',
};

const BUTTON_MAPPING = {
  [BUTTON_TYPES.PRIMARY]: {
    component: Button,
    options: {
      variant: 'primary',
    },
  },
  [BUTTON_TYPES.SECONDARY]: {
    component: Button,
    options: {
      variant: 'secondary',
    },
  },
  [BUTTON_TYPES.TEXT]: {
    component: ButtonText,
    options: {
      variant: 'secondary',
    },
  },
};

const LinkGroup = ({ links, color, isInverse, isBlock }) => {
  return links.map((link, i) => {
    const { component: Component, options = {} } = BUTTON_MAPPING[
      BUTTON_TYPES.TEXT
    ];

    if (!Component) {
      return null;
    }

    const linkOptions = { ...options, isInverse };

    if (isBlock && link.length === 1) {
      linkOptions.width = '100%';
    }
    const infoLink =
      link.type === 'page'
        ? setupPage(link, linkOptions)
        : setupLink(link, linkOptions);

    return (
      <Component
        mr={links.length > 0 && i === 0 ? '1' : '0'}
        mb={[1, 1, 0]}
        key={i}
        color={color}
        {...infoLink}
      >
        {infoLink.label}
      </Component>
    );
  });
};

LinkGroup.defaultProps = {
  isInverse: false,
};

export default LinkGroup;
