import React, { Fragment } from 'react';
import { TextField } from './ui/TextField';
import { SelectField } from './ui/SelectField';
import { TextAreaField } from './ui/TextAreaField';
import { CheckBoxField } from './ui/CheckboxField';
import { DateSelector } from './ui/DateSelector';
import { ButtonText } from '@dylanlewis/shared';
import { Flex } from 'rebass';
import { TimePicker } from './ui/TimePicker';
import { Text } from '@dylanlewis/shared';

export class FormContainer extends React.Component {
  render() {
    const { form, buttonText, renderTitle, isLoading } = this.props;

    return (
      <Fragment>
        {renderTitle ? renderTitle() : null}
        <form className="mb-3" onSubmit={this.props.onSubmit}>
          <div className="row">
            {form.map(fieldProps => {
              if (fieldProps.type === 'select')
                return <SelectField {...fieldProps} key={fieldProps.name} />;
              if (fieldProps.type === 'textArea')
                return (
                  <TextAreaField
                    {...fieldProps}
                    key={fieldProps.name}
                    {...this.props}
                  />
                );
              if (fieldProps.type === 'checkbox')
                return <CheckBoxField {...fieldProps} key={fieldProps.name} />;
              if (fieldProps.type === 'date')
                return (
                  <DateSelector
                    {...fieldProps}
                    key={fieldProps.name}
                    {...this.props}
                  />
                );
              if (fieldProps.type === 'time')
                return (
                  <TimePicker
                    {...fieldProps}
                    key={fieldProps.name}
                    {...this.props}
                  />
                );
              if (
                fieldProps.type === 'text' ||
                fieldProps.type === 'email' ||
                !fieldProps.type
              ) {
                return (
                  <TextField
                    {...fieldProps}
                    key={fieldProps.name}
                    {...this.props}
                  />
                );
              }
              if (fieldProps.type === 'description')
                return (
                  <Text
                    style={{ ...fieldProps }}
                    mt={[4]}
                    fontSize={'body'}
                    fontWeight="500"
                    fontFamily={'sans'}
                  >
                    {fieldProps.label}
                  </Text>
                  // </Box>
                );
              return null;
            })}
          </div>
          {this.props.children}
          <Flex justifyContent="center" mt={[1, 3]}>
            <ButtonText as="button">
              {isLoading ? 'Working' : buttonText}
            </ButtonText>
          </Flex>
        </form>
      </Fragment>
    );
  }
}

FormContainer.defaultProps = {
  buttonText: 'Send request',
};
