import React from 'react';
import styled from 'styled-components';
import { Text, fonts, colors } from '@dylanlewis/shared';

const StyledInput = styled.select`
  border-radius: 0px;
  background: ${colors.bgLight};
  border-color: ${colors.bgLight};
  padding: 8px;
  font-family: ${fonts.sans};
  &:active,
  &:focus {
    background: ${colors.bgLight};
    border-color: ${colors.borderDark};
    outline: none;
    box-shadow: none;
  }
`;

export const SelectField = props => {
  return (
    <div className={`${props.col ? props.col : 'col-3'}`}>
      <div className="form-group">
        <Text mb={[0]} fontSize={'body'} fontWeight="500" fontFamily={'sans'}>
          {props.label}
        </Text>
        <StyledInput
          className="form-control"
          name={props.name}
          disabled={props.disabled}
          required={props.required}
        >
          <option defaultValue value={props.defaultValue.value}>
            {props.defaultValue.label
              ? props.defaultValue.label
              : 'Please choose...'}
          </option>
          {props.value.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </StyledInput>
      </div>
    </div>
  );
};
