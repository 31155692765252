import React from 'react';
import {
  Text,
  Hero,
  Space,
  spacing,
  Container,
  Logo,
  Row,
  Column,
  LegalFooter,
} from '@dylanlewis/shared';
import styled from 'styled-components';
import { FormContainer } from '../../Form/FormContainer';
import { catalogueForm } from '../../../common/forms';
import serialize from 'form-serialize';
import { Mutation } from 'react-apollo';
import { CATALOGUE } from '../mutations/mutations';
import { Box } from 'rebass';

const StyledBox = styled(Box)`
  min-height: 100vh;
  background: #8f8e8d;
  padding-bottom: 100px;
`;
export class PageCatalogue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firsNameWarning: undefined,
      lastNameWarning: undefined,
      emailWarning: undefined,
      addressWarning: undefined,
      townWarning: undefined,
      zipCodeWarning: undefined,
      graphqlError: false,
      requestCompleted: false,
    };
  }

  validateForm(obj) {
    const { firstName, lastName, email, address, town, zipCode } = obj;

    let hasError = false;

    if (!obj) hasError = true;

    if (!firstName) {
      this.setState({ firsNameWarning: true });
      hasError = true;
    } else if (firstName) {
      this.setState({ firsNameWarning: false });
    }

    if (!lastName) {
      this.setState({ lastNameWarning: true });
      hasError = true;
    } else if (lastName) {
      this.setState({ lastNameWarning: false });
    }

    if (!email) {
      this.setState({ emailWarning: true });
      hasError = true;
    } else if (email) {
      this.setState({ emailWarning: false });
    }

    if (!address) {
      this.setState({ addressWarning: true });
      hasError = true;
    } else if (address) {
      this.setState({ addressWarning: false });
    }

    if (!town) {
      this.setState({ townWarning: true });
      hasError = true;
    } else if (town) {
      this.setState({ townWarning: false });
    }

    if (!zipCode) {
      this.setState({ zipCodeWarning: true });
      hasError = true;
    } else if (zipCode) {
      this.setState({ zipCodeWarning: false });
    }

    return hasError;
  }

  render() {
    const { heroDetails } = this.props;

    const onSubmit = async (e, send) => {
      e.preventDefault();

      var obj = serialize(e.target, { hash: true });

      const hasErrors = this.validateForm(obj);

      if (hasErrors) return null;

      e.target.reset();

      const {
        firstName,
        lastName,
        email,
        address,
        town,
        country,
        zipCode,
      } = obj;

      await send({
        variables: {
          fields: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            country: country,
            address: address,
            town: town,
            zipCode: zipCode,
          },
          form: 'CATALOGUE',
        },
      });

      this.setState({
        requestCompleted: true,
      });

      return null;
    };

    return (
      <React.Fragment>
        <StyledBox>
          <Space bottom size={spacing.space10}>
            <Container mb={10}>
              <Box pt={'16px'}>
                <Logo template={'studio'} isopen={true} />
              </Box>
              {!this.state.requestCompleted ? (
                <React.Fragment>
                  <Hero
                    renderVisual={heroDetails.visual}
                    subtitle={heroDetails.subtitle}
                    title={heroDetails.title}
                  >
                    {heroDetails.content && (
                      <Text size="body" as="span" html={heroDetails.content} />
                    )}
                  </Hero>

                  <Row flexDirection="column">
                    <Column width={[1, 8 / 12]} mx="auto">
                      <Mutation
                        mutation={CATALOGUE}
                        onCompleted={() =>
                          this.setState({ requestCompleted: true })
                        }
                        onError={() => this.setState({ graphqlError: true })}
                      >
                        {send => {
                          return (
                            <FormContainer
                              form={catalogueForm(this.state)}
                              onSubmit={e => onSubmit(e, send)}
                            />
                          );
                        }}
                      </Mutation>
                    </Column>
                  </Row>
                </React.Fragment>
              ) : (
                <Hero
                  renderVisual={heroDetails.visual}
                  title={!this.state.graphqlError ? 'Thank you' : 'Error'}
                >
                  <Text size="body" textAlign="center" as="span">
                    {!this.state.graphqlError
                      ? 'We have received your request.'
                      : 'Something went wrong, please contact the web administrator for assistance'}
                  </Text>
                </Hero>
              )}
            </Container>
          </Space>
          <LegalFooter />
        </StyledBox>
      </React.Fragment>
    );
  }
}
