import React from 'react';
import { NavLink } from 'react-router-dom';

export const visitTheGardenForm = errors => {
  return [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.firstName ? errors.firstName : null,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.lastName ? errors.lastName : null,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.email ? errors.email : null,
    },
    {
      label: 'Contact Number',
      name: 'phone',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.phone ? errors.phone : null,
    },
    {
      label: 'Date',
      name: 'date',
      type: 'date',
      col: 'col-md-6',
      format: 'date',
      required: true,
      error: errors && errors.date ? errors.date : null,
    },
    {
      label: 'Time',
      name: 'time',
      type: 'time',
      col: 'col-md-6',
      format: 'time',
      required: true,
      error: errors && errors.time ? errors.time : null,
    },
    {
      label: 'Number of guests',
      name: 'number',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.number ? errors.number : null,
    },
    {
      label: 'Country',
      name: 'country',
      type: 'select',
      col: 'col-md-6',
      defaultValue: { value: '', label: 'Please select...' },
      value: country_list.map(c => {
        return { value: c, label: c };
      }),
    },
    {
      label: 'Have you visited the sculpture garden before?',
      value: [
        {
          value: 'yes',
          label: 'Yes',
        },
        {
          value: 'no',
          label: 'No',
        },
      ],
      name: 'visitedBefore',
      type: 'select',
      col: 'col-md-12',
      defaultValue: { value: false, label: 'Please Choose...' },
      required: true,
    },
    // {
    //   label: 'Request a private guided tour',
    //   value: [
    //     {
    //       value: 'Yes, please send me costing details.',
    //       label: 'Yes, please send me costing details.',
    //     },
    //     {
    //       value: 'No thank you, I prefer a self-guided meander.',
    //       label: 'No thank you, I prefer a self-guided meander.',
    //     },
    //   ],
    //   name: 'privateTour',
    //   type: 'select',
    //   col: 'col-md-12',
    //   defaultValue: { value: false, label: 'Please Choose...' },
    // },
    {
      label: 'Message: Please include any specific interests (I.e. art, architecture, horticulture) or other information that may provide an insight into the group and allow us to tailor the experience as best possible.',
      name: 'comments',
      type: 'textArea',
      col: 'col-12',
      rows: 5,
      cols: 40,
      required: true,
      error: errors && errors.comments ? errors.comments : null,
    },
    {
      label: (
        <NavLink to={'/garden/terms-of-entry/'}>
          I agree to the Terms and Conditions of visiting the garden
        </NavLink>
      ),
      name: 'agree',
      type: 'checkbox',
      id: 'agree',
      required: true,
      error: errors && errors.agree ? errors.agree : null,
    },
    {
      label:
        "I'd like to receive the most recent information about Dylan Lewis and the Sculpture Garden",
      name: 'mailingList',
      type: 'checkbox',
      id: 'mailingList',
    },
  ];
};

export const contact = (errors, enquireOptions, defaultValue) => {
  return [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.firstName ? errors.firstName : null,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.lastName ? errors.lastName : null,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.email ? errors.email : null,
    },
    {
      label: 'Contact Number',
      name: 'phone',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.phone ? errors.phone : null,
    },
    {
      label: 'Enquiring About',
      name: 'enquire',
      type: 'select',
      col: 'col-md-6',
      defaultValue: defaultValue,
      value: enquireOptions,
      required: true,
    },
    {
      label: 'Country',
      name: 'country',
      type: 'select',
      col: 'col-md-6',
      defaultValue: { value: '', label: 'Please select...' },
      value: country_list.map(c => {
        return { value: c, label: c };
      }),
    },
    {
      label: 'Message',
      name: 'comments',
      type: 'textArea',
      col: 'col-12',
      rows: 5,
      cols: 40,
      required: true,
      error: errors && errors.comments ? errors.comments : null,
    },
    {
      label:
        "I'd like to receive the most recent information about Dylan Lewis and the Sculpture Garden",
      name: 'mailingList',
      type: 'checkbox',
      id: 'mailingList',
    },
  ];
};

export const subscribe = errors => {
  return [
    {
      label: 'First name',
      name: 'firstName',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.firstName ? errors.firstName : null,
    },
    {
      label: 'Last name',
      name: 'lastName',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.lastName ? errors.lastName : null,
    },
    {
      label: 'Country',
      name: 'country',
      type: 'select',
      col: 'col-md-6',
      defaultValue: { value: '', label: 'Please select...' },
      value: country_list.map(c => {
        return { value: c, label: c };
      }),
    },
    {
      label: 'Have you visited the sculpture garden before?',
      value: [
        {
          value: 'yes',
          label: 'Yes',
        },
        {
          value: 'no',
          label: 'No',
        },
      ],
      name: 'visitedBefore',
      type: 'select',
      col: 'col-md-6',
      defaultValue: { value: false, label: 'Please Choose...' },
      required: true,
    },
    {
      label: 'Email address',
      name: 'email',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.email ? errors.email : null,
    },
    {
      label:
        "I'd like to receive the most recent information about Dylan Lewis and the Sculpture Garden",
      name: 'subscribeGarden',
      type: 'checkbox',
      id: 'subscribeGarden',
      required: true,
      error: errors && errors.subscribeGarden ? errors.subscribeGarden : null,
    },
  ];
};

export const unsubscribe = errors => {
  return [
    {
      label: 'Email address',
      name: 'email',
      type: 'text',
      col: 'col-md-6',
      required: true,
      error: errors && errors.email ? errors.email : null,
    },
    {
      label:
        "Dylan Lewis Studio: I wish to receive updates relating to Dylan's art, such as upcoming exhibitions, new work and news.",
      name: 'subscribeStudio',
      type: 'checkbox',
      id: 'subscribeStudio',
    },
    {
      label:
        'Dylan Lewis Sculpture Garden: I wish to receive updates relating to the Sculpture Garden, such as upcoming exhibitions, events and news.',
      name: 'subscribeGarden',
      type: 'checkbox',
      id: 'subscribeGarden',
    },
    {
      label:
        'UNSUBSCRIBE to all communication from the Dylan Lewis Studio and Sculpture Garden.',
      name: 'unsubscribe',
      type: 'checkbox',
      id: 'unsubscribe',
    },
    {
      label: 'Reason for unsubscribing',
      name: 'reason',
      id: 'reason',
      type: 'textArea',
      col: 'col-12',
      rows: 5,
      cols: 40,
      required: true,
      error: errors && errors.info ? errors.info : null,
    },
  ];
};

export const catalogueForm = (errorState, selectedCountry) => {
  const {
    firsNameWarning,
    lastNameWarning,
    emailWarning,
    addressWarning,
    townWarning,
    zipCodeWarning,
  } = errorState;

  return [
    {
      label: 'First Name',
      name: 'firstName',
      type: 'text',
      col: 'col-md-6',
      error: firsNameWarning,
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'text',
      col: 'col-md-6',
      error: lastNameWarning,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      col: 'col-md-12',
      error: emailWarning,
    },
    {
      label: 'Address',
      name: 'address',
      type: 'textArea',
      col: 'col-md-12',
      rows: 5,
      cols: 40,
      error: addressWarning,
    },
    {
      label: 'Town',
      name: 'town',
      type: 'text',
      col: 'col-md-6',
      error: townWarning,
    },
    {
      label: 'Country',
      name: 'country',
      type: 'select',
      col: 'col-md-6',
      defaultValue: { value: '', label: 'Please select...' },
      value: country_list.map(c => {
        return { value: c, label: c };
      }),
    },
    {
      label: 'Zip Code',
      name: 'zipCode',
      type: 'text',
      col: 'col-md-6',
      error: zipCodeWarning,
    },
  ];
};

const country_list = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua &amp; Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia &amp; Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre &amp; Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts &amp; Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad &amp; Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks &amp; Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Other',
];
