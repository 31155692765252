import React from 'react';
import {
  Text,
  Hero,
  Space,
  spacing,
  Container,
  Row,
  Column,
} from '@dylanlewis/shared';
import { FormContainer } from '../../Form/FormContainer';
import { contact } from '../../../common/forms';
import serialize from 'form-serialize';
import { Mutation } from 'react-apollo';
import { CONTACT } from '../mutations/mutations';

export class PageContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };
  }

  validateForm(obj) {
    let errors = {};

    contact().forEach(field => {
      const { name, required } = field;

      if (required && !obj[name]) {
        errors[name] = 'Required';
      }

      if (obj.email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmail = re.test(String(obj.email).toLowerCase());
        if (!isEmail) {
          errors.email = 'Invalid email address';
        }
      }
    });

    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      return true;
    } else {
      this.setState({ errors: {} });
      return false;
    }
  }

  enquireOptions(slug) {
    let options = [];

    if (slug === 'contact-garden') {
      options = [
        { value: 'PRESS', label: 'Press' },
        { value: 'INFO', label: 'General Information' },
      ];
    }

    if (slug === 'contact-studio') {
      options = [
        { value: 'SALES', label: 'Sales enquiry' },
        { value: 'GARDEN', label: 'Sculpture Garden' },
      ];
    }

    return options;
  }

  render() {
    const { page, heroDetails } = this.props;

    const onSubmit = async (e, send) => {
      e.preventDefault();

      var obj = serialize(e.target, { hash: true });

      const hasErrors = this.validateForm(obj);

      if (hasErrors) return null;

      let form = '';

      if (page.slug === 'contact-garden') {
        form = 'GARDEN';
      }

      if (page.slug === 'contact-studio') {
        form = 'STUDIO';
      }

      const {
        firstName,
        lastName,
        email,
        phone,
        comments,
        enquire,
        mailingList,
        country,
      } = obj;

      await send({
        variables: {
          fields: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            message: comments,
            mailingList: mailingList ? 'Yes' : 'No',
            country: country,
          },
          enquiry: enquire,
          form: form,
          confirmationEmail: true,
        },
      });

      if (e && e.target) e.target.reset();

      return null;
    };

    return (
      <Container>
        <Hero
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title}
        >
          {heroDetails.content && (
            <Text
              textAlign="center"
              size="body"
              as="span"
              html={heroDetails.content}
            />
          )}
        </Hero>
        <Space bottom size={spacing.space10}>
          <Space padding bottom size={spacing.space5}>
            <Row justifyContent="space-between">
              <Column width={[1, 5 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
                <Text mb={[2, 5]} size="body" as="span" html={page.content} />
              </Column>
              <Column width={[1, 5 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
                {!this.state.emailHasBeenSent ? (
                  <Mutation
                    mutation={CONTACT}
                    onCompleted={response =>
                      response.submitForm
                        ? this.setState({ emailHasBeenSent: true })
                        : this.setState({ emailHasBeenSent: false })
                    }
                    onError={() => this.setState({ emailError: true })}
                  >
                    {(send, { loading, error }) => {
                      return (
                        <FormContainer
                          form={contact(
                            this.state.errors,
                            this.enquireOptions(page.slug),
                            page.slug === 'contact-garden'
                              ? { value: 'EVENTS', label: 'Private Events' }
                              : { value: 'INFO', label: 'General information' },
                          )}
                          isLoading={loading}
                          onSubmit={e => onSubmit(e, send)}
                        />
                        // <FormContainer
                        //   form={test(this.state.errors)}
                        //   isLoading={loading}
                        //   onSubmit={e => onSubmit(e, send)}
                        // />
                      );
                    }}
                  </Mutation>
                ) : (
                  <Text>
                    Thank you for your enquiry. We will reply by email or phone
                    call as soon as possible within our working hours.
                  </Text>
                )}
              </Column>
            </Row>
          </Space>
        </Space>
      </Container>
    );
  }
}
