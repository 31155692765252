import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import * as fetch from 'isomorphic-fetch';

import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export default new ApolloClient({
  connectToDevTools: process.browser,
  ssrMode: !process.browser,
  link: ApolloLink.from([
    new HttpLink({
      uri: process.env.RAZZLE_GQL_API || 'http://localhost:4000/graphql',
      fetch: fetch,
    }),
  ]),
  cache:
    process.browser && typeof window !== 'undefined'
      ? new InMemoryCache({ fragmentMatcher }).restore(window.__APOLLO_STATE__)
      : new InMemoryCache({ fragmentMatcher }),
});
