import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Text,
  spacing,
  opacity,
  timing,
  fontWeight,
  fonts,
  tracking,
  colors,
  Container,
  Column,
  Row,
} from '@dylanlewis/shared';
import { Box } from 'rebass';
import MapImg from '../../../common/images/Map.jpg';
import Birds from '../../../common/images/Birds.png';
import BigCats from '../../../common/images/BigCats.png';
import AfricanAnimals from '../../../common/images/AfricanAnimals.png';
import LeopardFragments from '../../../common/images/LeopardFragments.png';
import HumanTorsos from '../../../common/images/HumanTorsos.png';
import EarlyFemaleFigures from '../../../common/images/EarlyFemaleFigures.png';
import ShamanicFemaleFigures from '../../../common/images/ShamanicFemaleFigures.png';
import ShamanicMaleFigures from '../../../common/images/ShamanicMaleFigures.png';
import MonumentalFragments from '../../../common/images/MonumentalFragments.png';
import RecentWork from '../../../common/images/RecentWork.png';
import { Link } from 'react-router-dom';

const StyledGardenMap = styled(Box)`
  background-color: #fafafa;
`;

const StyledMapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledMap = styled.div`
  padding-bottom: 100%;
  background-size: cover;
  position: relative;
  background-image: url(${MapImg});
`;

const StyledMapArea = styled.div`
  padding-bottom: 100%;
  background-size: cover;
  transition: all ${timing.slow};
  background-image: ${props => `url(${props.map})`};
  opacity: ${props => (props.active === true ? '1' : '0')};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledList = styled.ul`
  margin: 16px 0 0 0;
  padding: 0;
  list-style-type: 'none';
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  pointer-events: ${props => (props.active ? 'none' : 'default')};
  cursor: ${props => (props.active ? 'default' : 'pointer')};
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: ${fonts.sans};
  font-size: 19px;
  line-height: 25px;
  font-weight: ${fontWeight.light};
  letter-spacing: ${tracking.small};
  color: ${colors.gardenText};
  margin-bottom: ${spacing.space1};
  font-weight: ${props =>
    props.active ? fontWeight.medium : fontWeight.light};
`;

const StyledIcon = styled.i`
  opacity: ${opacity.transparent};
  margin-left: ${spacing.space1};
  transition: opacity ${timing.slow};
  color: #000;
  font-size: 16px !important;
  opacity: ${props => (props.active ? opacity.solid : opacity.transparent)};
  ${StyledItem}:hover & {
    opacity: ${opacity.solid};
  }
`;

export const GardenMap = ({ group }) => {
  const currentArea = mapAreaList.find(m => m.slug === group);

  const [activeMap, setActiveMap] = useState(
    currentArea && currentArea.map ? currentArea.map : MapImg,
  );

  const mouseOver = map => {
    setActiveMap(map);
  };

  return (
    <StyledGardenMap pt={[18]}>
      <Container>
        <Row>
          <Column width={[1, 4 / 12]}>
            <Box mb={[5]}>
              <Text size="body" mt={[3, 12]} mb={[3]}>
                Dylan has grouped the sculptures within the garden not as a
                response to a conscious plan but rather, as he describes it,
                through a process that unfolded intuitively over many years, in
                which certain sculptures seemed to ‘gather’ into distinct areas.
              </Text>

              <StyledList>
                {mapAreaList.map((a, index) => {
                  const { slug = null, map, label } = a;

                  let isActive =
                    currentArea && slug && slug === currentArea.slug
                      ? true
                      : false;

                  return label !== null ? (
                    <StyledItem
                      key={index}
                      onMouseOver={() => mouseOver(map)}
                      active={isActive}
                    >
                      <StyledLink
                        to={`/garden/sculpture_groupings/${slug}`}
                        active={isActive}
                        key={index}
                      >
                        {label}
                        <StyledIcon
                          active={isActive}
                          className="icon icon-circle"
                        />
                      </StyledLink>
                    </StyledItem>
                  ) : null;
                })}
              </StyledList>
            </Box>
          </Column>
          <Column width={[1, 8 / 12]}>
            <StyledMapWrapper>
              <StyledMap>
                {mapAreaList.map((m, index) => (
                  <StyledMapArea
                    key={index}
                    map={m.map}
                    active={activeMap === m.map ? true : false}
                  />
                ))}
              </StyledMap>
            </StyledMapWrapper>
            <div className="clearfix" />
          </Column>
        </Row>
      </Container>
    </StyledGardenMap>
  );
};

const mapAreaList = [
  {
    map: Birds,
    label: 'Birds',
    slug: 'birds',
  },
  {
    map: AfricanAnimals,
    label: 'African Animals',
    slug: 'african-animals',
  },
  {
    map: BigCats,
    label: 'Big Cats',
    slug: 'big-cats',
  },
  {
    map: LeopardFragments,
    label: 'Leopard Fragments',
    slug: 'fragmented-torsos',
  },
  {
    map: EarlyFemaleFigures,
    label: 'Early Female Figures',
    slug: 'early-female-figures',
  },
  {
    map: HumanTorsos,
    label: 'Human Torsos ',
    slug: 'fragmented-human-torsos',
  },
  {
    map: ShamanicFemaleFigures,
    label: 'Female Shamanic Figures',
    slug: 'female-shamanic-figures',
  },
  {
    map: ShamanicMaleFigures,
    label: 'Male Shamanic Figures',
    slug: 'male-shamanic-figures',
  },
  {
    map: MonumentalFragments,
    label: 'Monumental Fragments',
    slug: 'monumental-fragments',
  },
  {
    map: RecentWork,
    label: 'Recent Work',
    slug: 'recent-work',
  },
];
