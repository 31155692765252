import React from 'react';
import {
  Hero,
  Text,
  getHeroDetails,
  getButton,
  Share,
  Container,
  Row,
  Column,
  bannerContent,
} from '@dylanlewis/shared';
import BannerWrapper from '../../../components/BannerWrapper';
import { Layout } from '../../../shared/ui/Layout';
import { RelatedItems } from './RelatedItems';
import { ModalContent } from '../../../shared/ui/ModalContent';
import { Box } from 'rebass';
import { NavLink } from 'react-router-dom';

const heroProps = page => {
  const heroProps = getHeroDetails(page);
  if (page.type === 'auctions_exhibitions') {
    return {
      description: page.acf ? page.acf.description : null,
      title: page.title ? page.title : null,
      subtitle: page.date && page.date.length ? page.date[0].name : null,
      buttons: heroProps.buttons,
      visual: heroProps.visual,
      content: heroProps.content,
    };
  }

  return heroProps;
};

export const Item = props => {
  const { page, parent = null } = props;
  const heroDetails = heroProps(page);

  return (
    <React.Fragment>
      <Container>
        <Hero
          billboard={true}
          title={heroDetails && heroDetails.title}
          // The subtitle displays the Page title on the "Studio" side of the item. If the item is accessed via the "Garden", the subtitle should not be shown
          subtitle={heroDetails.subtitle}
          description={heroDetails.description}
          renderVisual={heroDetails.visual}
          renderCta={
            heroDetails.buttons
              ? heroDetails.buttons.map((b, index) => {
                  return (
                    <Box key={index} mx={[1, 2]} mb={[2, 0]}>
                      {getButton(
                        b.button,
                        false,
                        NavLink,
                        <ModalContent
                          {...b.button}
                          slug={b.button.page.post_name}
                          postType={b.button.page.post_type}
                        />,
                      )}
                    </Box>
                  );
                })
              : null
          }
        >
          {heroDetails.content && (
            <Text
              size="body"
              textAlign="center"
              as="span"
              html={heroDetails.content}
            />
          )}
        </Hero>
      </Container>

      <Container>
        <Row flexDirection="column">
          <Column width={[1, 8 / 12]} mx="auto">
            {page.content && (
              <Text mb={[2, 5]} size="body" as="span" html={page.content} />
            )}

            {props.page.type === 'events' && (
              <Share title={heroDetails.title} />
            )}
          </Column>
        </Row>
      </Container>
      <Layout blocks={page.acf} />
      {page.acf.button_options &&
        page.acf.button_options.buttons &&
        page.acf.button_options.buttons.map((b, index) => {
          return (
            <Container key={index}>
              <Row mb={[3, 8]} justifyContent="center">
                <Column textAlign="center">
                  {getButton(
                    b.button,
                    false,
                    NavLink,
                    <ModalContent
                      {...b.button}
                      slug={b.button.page.post_name}
                      postType={b.button.page.post_type}
                    />,
                  )}
                </Column>
              </Row>
            </Container>
          );
        })}
      {!props.isModal ? (
        <React.Fragment>
          <RelatedItems postType={page.type} page={page} parent={parent} />

          {props.parent && props.parent === 'garden' && (
            <BannerWrapper
              hasVisual={page.type === 'events' ? false : true}
              content={
                page.type === 'events'
                  ? bannerContent.event
                  : bannerContent.visit
              }
            />
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};
