import React from 'react';
import {
  Hero,
  Text,
  Container,
  Row,
  Column,
  Space, 
  spacing
} from '@dylanlewis/shared';
import { FormContainer } from '../../Form/FormContainer';
import { visitTheGardenForm } from '../../../common/forms';
import serialize from 'form-serialize';
import { BOOKING } from '../mutations/mutations';
import { Mutation } from 'react-apollo';
import {
  convertDateToString,
  convertTimeToString,
} from '../../../common/utils';

export class PageVisitGardenForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null,
      time: null,
      emailHasBeenSent: false,
      emailError: false,
      errors: {},
    };
  }

  validateForm(obj) {
    let errors = {};

    let hasErrors = false;

    visitTheGardenForm().forEach(field => {
      const { name, required } = field;

      if (required && !obj[name]) {
        errors[name] = 'Required';
      } else if (obj.email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmail = re.test(String(obj.email).toLowerCase());
        if (!isEmail) {
          errors.email = 'Invalid email address';
        }
      } else if (obj.time) {
        const d = new Date(obj.time);
        const hour = d.getHours();
        const min = d.getMinutes();

        if (hour === 15 && min > 30) {
          errors.time = 'Please select a time between 8:30 and 15:30';
        }

        if (hour === 8 && min < 30) {
          errors.time = 'Please select a time between 8:30 and 15:30';
        }

        if (hour < 8 || hour > 15) {
          errors.time = 'Please select a time between 8:30 and 15:30';
        }
      }
    });

    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      hasErrors = true;
    }

    return hasErrors;
  }

  async onSubmit(e, send) {
    e.preventDefault();

    let obj = serialize(e.target, { hash: true });

    obj.date = this.state.date;
    obj.time = this.state.time;

    const hasErrors = this.validateForm(obj);

    if (hasErrors) return null;

    const {
      firstName,
      lastName,
      email,
      phone,
      comments,
      number,
      privateTour,
      agree,
      mailingList,
      date,
      time,
      country,
      visitedBefore,
    } = obj;

    await send({
      variables: {
        fields: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          message: comments,
          numberOfGuests: Number(number),
          privateTour: privateTour,
          termsAndConditionsAgreed: agree ? 'Agreed' : 'Not agreed',
          mailingList: mailingList ? 'Yes' : 'No',
          date: convertDateToString(date),
          time: convertTimeToString(time),
          country,
          visitedBefore,
        },
        enquiry: 'VISIT',
        form: 'BOOKING',
        confirmationEmail: true,
      },
    });

    if (e && e.target) e.target.reset();

    return null;
  }

  render() {
    const { page, heroDetails } = this.props;

    const handleDateChange = date => {
      this.setState({
        date: date,
      });
    };

    const handleTimeChange = time => {
      this.setState({
        time: time,
      });
    };

    
    return (
      <Container>
        <Hero
          noCrop={heroDetails.noCrop}
          renderVisual={heroDetails.visual}
          subtitle={heroDetails.subtitle}
          title={heroDetails.title}
        >
          {heroDetails.content && (
            <Text
              textAlign="center"
              size="body"
              as="span"
              html={heroDetails.content}
            />
          )}
        </Hero>

        <Space bottom size={spacing.space10}>
          <Space padding bottom size={spacing.space5}>
            <Row justifyContent="center">
              <Column width={[1, 8 / 12]} mx="auto" flex="0 0 auto" mb={[4, 0]}>
                <Mutation
                mutation={BOOKING}
                onCompleted={response =>
                  response.submitForm
                    ? this.setState({ emailHasBeenSent: true })
                    : this.setState({ emailHasBeenSent: false })
                }
                onError={() => this.setState({ emailError: true })}
              >
              {(send, { loading, error }) => {
                return (
                  <React.Fragment>
                    <Container>
                      <Row>
                        <Column
                          width={[1, 12 / 12]}
                          mx="auto"
                          flex="0 0 auto"
                          mb={[2, 4]}
                        >
                          {!this.state.emailHasBeenSent ? (
                            <FormContainer
                              handleDateChange={handleDateChange}
                              handleTimeChange={handleTimeChange}
                              date={this.state.date}
                              time={this.state.time}
                              form={visitTheGardenForm(this.state.errors)}
                              onSubmit={e => this.onSubmit(e, send)}
                              isLoading={loading}
                              renderTitle={""}
                            />
                          ) : (
                            <Text>
                              Thank you for your booking enquiry. We will
                              reply by email or phone call as soon as possible
                              within our working hours.
                            </Text>
                          )}
                        </Column>
                      </Row>
                    </Container>
                  </React.Fragment>
                );
              }}
                </Mutation>
              </Column>
            </Row>
          </Space>
        </Space>
      </Container>
    );
  }
}
