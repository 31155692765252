import gql from 'graphql-tag';

export const PAGE_QUERY = gql`
  query viewPage($slug: String!) {
    viewPage(slug: $slug) {
      title
      content
      acf
      seo
      template
      isChildPage
      parent
      template
      slug
    }
  }
`;

export const GET_TEMPLATE = gql`
  query viewPage($slug: String!) {
    viewPage(slug: $slug) {
      template
      isChildPage
    }
  }
`;

export const GET_PAGES = gql`
  query ViewPage {
    studioPage: viewPage(slug: "dylan-lewis-studio") {
      title
      content
      acf
      seo
      template
      slug
    }
    gardenPage: viewPage(slug: "dylan-lewis-sculpture-garden") {
      title
      content
      acf
      seo
      template
      slug
    }
  }
`;
