import React from 'react';
import { Container, Text } from '@dylanlewis/shared';

export const NoMatch = () => {
  return (
    <Container py={[5, 15]}>
      <Text size="meta" textAlign="center">
        404
      </Text>
      <Text size="title" textAlign="center">
        Page not found
      </Text>
    </Container>
  );
};
