import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { mapStyle } from '../../../common/googleMapStyle';
import mapMarker from '../../../common/images/map_marker.png';

export const MapWithAMarker = withGoogleMap(props => {
  const {
    coords: { lat, lng },
  } = props;

  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: Number(lat), lng: Number(lng) }}
      defaultOptions={{ styles: mapStyle }}
    >
      <Marker
        position={{
          lat: Number(lat),
          lng: Number(lng),
        }}
        options={{
          icon: `${mapMarker}`,
        }}
      />
    </GoogleMap>
  );
});
