import React from 'react';
import './App.css';
import { HomeContainer } from './modules/Home/HomeContainer';
import { PageContainer } from '../src/modules/Page/PageContainer';
import { ItemContainer } from '../src/modules/Item/ItemContainer';
import { GET_PAGES } from './shared/queries/page';
import { DataQuery } from './shared/helpers/DataQuery';
import { Switch, Route, withRouter } from 'react-router-dom';
import { NavSwitchContainer } from './shared/ui/NavSwitchContainer';
import { Subscribe } from '../src/modules/Form/Newsletter/Subscribe';
import { Footer, theme, Menu, Row, Column, menus } from '@dylanlewis/shared';
import { ThemeProvider } from 'styled-components';
import { Box } from 'rebass';
import { NoMatch } from './shared/ui/NoMatch';
import { NavLink } from 'react-router-dom';

const GenericContainerComponent = ({ activeComponent, site }) => (
  <React.Fragment>
    {activeComponent}
    <FooterComponent site={site} />
  </React.Fragment>
);

const FooterComponent = ({ site }) => (
  <Footer template={site.activeParent}>
    <Row flexDirection="row" flexWrap="wrap">
      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={menus[site.activeParent].footer.title}
          titleUrl={menus[site.activeParent].footer.url}
          items={menus[site.activeParent].footer.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>

      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={
            site.activeParent === 'studio'
              ? menus.garden.footer.title
              : menus.studio.footer.title
          }
          titleUrl={
            site.activeParent === 'studio'
              ? menus.garden.footer.url
              : menus.studio.footer.url
          }
          items={
            site.activeParent === 'studio'
              ? menus.garden.footer.items.map(menu => {
                  return { ...menu, as: NavLink };
                })
              : menus.studio.footer.items.map(menu => {
                  return { ...menu, as: NavLink };
                })
          }
        />
      </Column>
      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        <Menu
          menuTitle={menus.contact.title}
          items={menus.contact.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
        <Menu
          menuTitle={menus.address.title}
          items={menus.address.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>

      <Column width={[6 / 12, 6 / 12, 3 / 12]} flex={'0 0 auto'}>
        {/* <Subscribe /> */}
        <Menu
          menuTitle={menus.social.title}
          items={menus.social.items.map(menu => {
            return { ...menu, as: NavLink };
          })}
        />
      </Column>
    </Row>
  </Footer>
);

class App extends React.Component {
  componentDidUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const site = this.navSwitchProps(this.props.location.pathname);
    return (
      <ThemeProvider theme={theme[site.activeParent]}>
        <DataQuery
          query={GET_PAGES}
          render={data => {
            return (
              <React.Fragment>
                <Switch>
                  <Route
                    exact
                    path={'/p/:slug'}
                    render={routeProps => {
                      return <PageContainer {...routeProps} theme="studio" />;
                    }}
                  />

                  <Box bg="bg">
                    <Switch>
                      <Route
                        exact
                        path={['/', '/studio']}
                        render={routerProps => {
                          return (
                            <NavSwitchContainer
                              site={site}
                              openComponent={
                                <GenericContainerComponent
                                  activeComponent={
                                    <PageContainer {...routerProps} />
                                  }
                                  site={site}
                                />
                              }
                              data={data}
                            />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/garden"
                        render={routeProps => {
                          return (
                            <NavSwitchContainer
                              site={site}
                              openComponent={
                                <GenericContainerComponent
                                  activeComponent={
                                    <HomeContainer pages={data.gardenPage} />
                                  }
                                  site={site}
                                />
                              }
                              data={data}
                            />
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/:parent/:slug"
                        render={routerProps => {
                          const {
                            match: {
                              params: { parent },
                            },
                          } = routerProps;
                          if (parent === 'studio' || parent === 'garden') {
                            return (
                              <NavSwitchContainer
                                site={site}
                                openComponent={
                                  <GenericContainerComponent
                                    activeComponent={
                                      <PageContainer {...routerProps} />
                                    }
                                    site={site}
                                  />
                                }
                                data={data}
                              />
                            );
                          }

                          return <NoMatch />;
                        }}
                      />
                      <Route
                        exact
                        path="/:parent/:postType/:slug"
                        render={routerProps => {
                          const {
                            match: {
                              params: { parent },
                            },
                          } = routerProps;
                          if (parent === 'studio' || parent === 'garden') {
                            return (
                              <NavSwitchContainer
                                site={site}
                                openComponent={
                                  <GenericContainerComponent
                                    activeComponent={
                                      <ItemContainer {...routerProps} />
                                    }
                                    site={site}
                                  />
                                }
                                data={data}
                              />
                            );
                          }

                          return <NoMatch />;
                        }}
                      />
                      <Route component={NoMatch} />
                    </Switch>
                  </Box>
                </Switch>
              </React.Fragment>
            );
          }}
        />
      </ThemeProvider>
    );
  }

  navSwitchProps = pathname => {
    const splitName = pathname.split('/');
    const path =
      splitName.length && splitName[1] === 'garden'
        ? ['garden', 'studio']
        : ['studio', 'garden'];

    return {
      activeParent: path[0],
      otherParent: path[1],
      logo: path,
    };
  };
}

export default withRouter(App);
