import React from 'react';
import { Item } from './ui/Item';
import { HeaderMeta } from '../../shared/ui/Helmet';
import { DataQuery } from '../../shared/helpers/DataQuery';
import { ITEM_QUERY } from '../../shared/queries/item';
import { NoMatch } from '../../shared/ui/NoMatch';
export class ItemContainer extends React.Component {
  render() {
    const {
      match: {
        params: { slug, postType, parent },
      },
    } = this.props;

    return (
      <DataQuery
        query={ITEM_QUERY}
        variables={{
          slug: slug,
          postType: postType,
        }}
        render={data => {
          if (!data.viewItem) {
            return <NoMatch />;
          }
          return (
            <React.Fragment>
              {data.viewItem.seo ? (
                <HeaderMeta seo={data.viewItem.seo} />
              ) : null}
              <Item page={data.viewItem} {...this.props} parent={parent} />
            </React.Fragment>
          );
        }}
      />
    );
  }
}
